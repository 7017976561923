import {EditorState, Modifier, RichUtils, SelectionState} from "draft-js";

export function sc_handleConvertToEm(editorState) {
    const selection = editorState.getSelection();
    const cs = editorState.getCurrentContent();
    const focus_block_key = selection.getAnchorKey();
    const content_block = cs.getBlockForKey(focus_block_key);
    const temp_selection = SelectionState.createEmpty(focus_block_key);
    const updatedSelection = temp_selection.merge({
        focusKey: focus_block_key,
        anchorOffset: parseInt(selection.getAnchorOffset()-1),
        focusOffset: content_block.getLength(),
    });

    const ncs = Modifier.replaceText(cs,updatedSelection,"— ");

    const newContent = ncs.merge({
        selectionBefore: selection,
        selectionAfter: ncs.getSelectionAfter().set('hasFocus', true)
    });

    return EditorState.push(editorState, newContent, 'remove-range');
}