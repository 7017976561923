import {Entity, Modifier} from "draft-js";

export function scEditorHandleAddLink(meta,editorState,cb=()=>{}) {
    const {selection_link,selection_text} = meta;
    const current_content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const entityKey = Entity.create('LINK', 'IMMUTABLE', {"text":selection_text,"value":selection_link,"url":selection_link});

    const textWithEntity = Modifier.replaceText(current_content, selection, selection_text, null, entityKey);

    cb(textWithEntity);
}