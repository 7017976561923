import React from 'react';
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';

const demo = `
# GFM

## Autolink literals

www.example.com, https://example.com, and contact@example.com.

## Footnote

A note[^1]

[^1]: Big note.

## Strikethrough

~one~ or ~~two~~ tildes.

## Table

| a | b  |  c |  d  |
| - | :- | -: | :-: |

## Tasklist

* [ ] to do
* [x] done
`;

export function MarkdownViewer({content='',fill=false,size="prose-sm"}) {

    return <div className={`prose ${fill?"w-full max-w-full":""} ${size} mx-auto`}><ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown></div>
}