export const leaders_placeholders = [
    {
        first_name: 'John',
        last_name: 'Muir',
        email: 'john.muir@getunaty.com'
    },
    {
        first_name: 'Mahatma',
        last_name: 'Gandhi',
        email: 'mahatma.gandhi@getunaty.com'
    },
    {
        first_name: 'Martin Luther',
        last_name: 'King Jr.',
        email: 'mlk@getunaty.com'
    },
    {
        first_name: 'George',
        last_name: 'Washington',
        email: 'george.washington@getunaty.com'
    },
    {
        first_name: 'Abraham',
        last_name: 'Lincoln',
        email: 'abraham.lincoln@getunaty.com'
    },
    {
        first_name: 'Susan B.',
        last_name: 'Anthony',
        email: 'susan.b.anthony@getunaty.com'
    },
    {
        first_name: 'Theodore',
        last_name: 'Roosevelt',
        email: 'teddy@getunaty.com'
    }
];

export const leaders_placeholders_len = leaders_placeholders.length;