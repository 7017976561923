import styled from "styled-components";

export const ScEditorToolbarButtons = styled.div`

`;

export const ScEditorContent = styled.div`
  line-height: 1.6rem !important;

  h1 {
    font-size: 19px !important;
    font-weight: bold;
    margin-top: 1rem !important;
    margin-bottom: 0.4rem !important;
  }

  hr {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }

  h2 {
    font-size: 17px !important;
    font-weight: bold;
    margin-top: 0.7rem !important;
    margin-bottom: 0.4rem !important;
  }

  h3 {
    margin-top: 1.2rem !important;
    margin-bottom: 0.6rem !important;
  }

  ul, ol {
    margin: 0.4rem 0 0.6rem 0 !important;
  }

  ul {
    list-style: none !important;
  }

  ul > li::marker {
    display: none !important;
  }

  ul > li {
    list-style: none !important;

  }

  ul > li.public-DraftStyleDefault-depth0 {
    margin-left: 0.4rem !important;
    padding-left: 1.5rem !important;
  }

  ul > li.public-DraftStyleDefault-depth1 {
    margin-left: 1.6rem !important;
    padding-left: 1.5rem !important;
  }

  ul > li.public-DraftStyleDefault-depth2 {
    margin-left: 2.8rem !important;
    padding-left: 1.5rem !important;
  }

  ul > li.public-DraftStyleDefault-depth3 {
    margin-left: 4rem !important;
    padding-left: 1.5rem !important;
  }

  ol > li {

  }

  ol > li.public-DraftStyleDefault-depth0 {
    padding-left: 0.3rem !important;
    margin-left: 1.6rem !important;
  }

  ol > li.public-DraftStyleDefault-depth1 {
    padding-left: 0.3rem !important;
    margin-left: 2.8rem !important;
  }

  ol > li.public-DraftStyleDefault-depth2 {
    padding-left: 0.3rem !important;
    margin-left: 4rem !important;
  }

  ol > li.public-DraftStyleDefault-depth3 {
    padding-left: 0.3rem !important;
    margin-left: 5.2rem !important;
  }

  figure[data-block=true] {
    margin: 0.5rem 0 !important;
    height: 2px;
    background: #e6e6e6;
    margin: 0.5rem 0 !important;
    height: 2px !important;
    background: #e6e6e6 !important;
  }

  blockquote {
    font-style: normal;
    border-left: 2px solid #2f2f2f;
    padding-left: 0.72rem;
    font-weight: normal;
    padding-top: 0.05rem;
    margin-left: -2px !important;
    padding-bottom: 0.05rem;
    margin-top: 0.3rem !important;
    margin-bottom: 0.3rem;
  }
`;

export const ScEditorContentSmall = styled.div`
  h1 {
    font-size: 17px !important;
    font-weight: bold;
    margin-top: 1rem !important;
    margin-bottom: 0.4rem !important;
  }

  h2 {
    font-size: 16px !important;
    font-weight: bold;
    margin-top: 0.7rem !important;
    margin-bottom: 0.4rem !important;
  }

  h3 {
    margin-top: 1.2rem !important;
    margin-bottom: 0.6rem !important;
  }

  ul, ol {
    margin: 0.4rem 0 0.6rem 0 !important;
  }

  ul {
    list-style: none !important;
  }

  ul > li::marker {
    display: none !important;
  }

  ul > li {
    list-style: none !important;

  }

  ul > li.public-DraftStyleDefault-depth0 {
    margin-left: 0.4rem !important;
    padding-left: 1.5rem !important;
  }

  ul > li.public-DraftStyleDefault-depth1 {
    margin-left: 1.6rem !important;
    padding-left: 1.5rem !important;
  }

  ul > li.public-DraftStyleDefault-depth2 {
    margin-left: 2.8rem !important;
    padding-left: 1.5rem !important;
  }

  ul > li.public-DraftStyleDefault-depth3 {
    margin-left: 4rem !important;
    padding-left: 1.5rem !important;
  }

  ol > li {

  }

  ol > li.public-DraftStyleDefault-depth0 {
    padding-left: 0.3rem !important;
    margin-left: 1.6rem !important;
  }

  ol > li.public-DraftStyleDefault-depth1 {
    padding-left: 0.3rem !important;
    margin-left: 2.8rem !important;
  }

  ol > li.public-DraftStyleDefault-depth2 {
    padding-left: 0.3rem !important;
    margin-left: 4rem !important;
  }

  ol > li.public-DraftStyleDefault-depth3 {
    padding-left: 0.3rem !important;
    margin-left: 5.2rem !important;
  }

  div[data-block=true] {
    margin-bottom: 0.2rem !important;
  }

  figure[data-block=true] {
    margin: 0.5rem 0 !important;
    height: 2px;
    background: #e6e6e6;
    margin: 0.5rem 0 !important;
    height: 2px !important;
    background: #e6e6e6 !important;
  }
  
  blockquote {
    border-left: 3px solid #b1b1b1;
    border-radius: 0.2rem;
    padding-left: 0.875rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    margin-top: 0.2rem !important;
    margin-bottom: 0.4rem;
    font-style: italic;
  }
`;