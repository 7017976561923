import React, {useEffect,useState} from 'react';

export function TXTPreview({url}) {
    const [loaded,setLoaded] = useState(false);

    useEffect(function() {
        function populatePre(url) {
            setLoaded(true);
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                document.getElementById('txt-contents').textContent = this.responseText;
            };
            xhr.open('GET', url);
            xhr.send();
        }
        if(!loaded) {
            populatePre(url);
        }

    }, [url]);

    return <div className="bg-white mx-auto">
        <div className="text-base h-full py-8 max-w-2xl mx-auto">
        <div id="txt-contents">

    </div>
        </div>
    </div>
}