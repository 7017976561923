import React from 'react';
import styled from "styled-components";

const Frame = styled.div`
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding-left: 6px;
`;

const TitleSection = styled.div`
    display: flex;
    height: 24px;
`;

const IconBox = styled.div`
    svg {
            height: 18px;
    }
`;

export function ImportSection({children, title, icon}) {

    return <Frame className='pt-2'>
        <div className="pb-1.5">
            <TitleSection>
                <IconBox className='text-gray-800 flex items-center'>
                    {icon}
                </IconBox>
                <Title className='text-gray-900'>{title}</Title>
            </TitleSection>
        </div>
        <div>
            {children}
        </div>
    </Frame>
}