export const sc_editor_style_map = {
    'CODE': {
        background: "#f7f7f7",
        padding: "1px 3px",
        borderRadius: "3px",
        border: "1px solid #efefef",
        fontSize: "0.9rem",
        color: "#dc185b",
        fontFamily: "monospace"
    },
    'BOLD': {
        fontWeight: '600'
    },
    'STRIKETHROUGH': {
        textDecoration: 'line-through',
    },
};