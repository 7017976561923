export const mock_live_import_editor_data = {
  1: {
      'first name': 'James',
      'Last Name': 'Smith',
      'members type': 'ambassador',
      'email': 'james@smith.com',
      'secondary emails': '',
      'phone home': '+1 262 825-2883',
      'date of birth': '21-3-1995',
      'home country': 'us',
      'fav date': '',
      'receptionsjahr': ''
  },
    2: {
        'first name': 'Amy',
        'Last Name': 'Smith',
        'members type': 'member',
        'email': 'amy@smith.com',
        'phone home': '23034235123',
        'secondary emails': 'amy@one.com amy@two.com',
        'date of birth': '6-4-1995',
        'home country': 'de',
        'fav date': '',
        'receptionsjahr': ''
    },
    3: {
        'first name': 'Fabian',
        'Last Name': 'Ahrens',
        'members type': 'ambassador',
        'email': 'ahre ns@getunaty.com',
        'phone home': '2934230523',
        'secondary emails': '',
        'date of birth': '14-14-1995',
        'home country': 'France ',
        'fav date': '',
        'receptionsjahr': ''
    },
    4: {
        'first name': 'Ramin',
        'Last Name': 'Rente',
        'members type': 'ambassador',
        'email': 'rente@getunaty.com',
        'secondary emails': '',
        'phone home': '392340234',
        'date of birth': '',
        'home country': 'us',
        'fav date': '',
        'receptionsjahr': ''
    },
    5: {
        'first name': 'Hunter',
        'Last Name': 'Grand',
        'members type': 'member',
        'email': 'hunter@getunaty.com',
        'phone home': '5239823423',
        'secondary emails': '',
        'date of birth': '12.1.2000',
        'home country': 'NL',
        'fav date': '',
        'receptionsjahr': ''
    },
    6: {
        'first name': 'Josie',
        'Last Name': 'Cotton',
        'members type': '',
        'email': 'jojo@getunaty.com',
        'phone home': '5102382323',
        'date of birth': '11-11-1967',
        'secondary emails': '',
        'home country': 'us',
        'fav date': '',
        'receptionsjahr': ''
    }
};

export const mock_lie_columns = [
    {
        id: '1-first name',
        label: 'first name'
    },
    {
        id: '2-Last Name',
        label: 'Last Name'
    },
    {
        id: '3-email',
        label: 'email'
    },
    {
        id: '4-phone home',
        label: 'phone home'
    },
    {
        id: '5-members type',
        label: 'members type',
    },
    {
        id: '6-date of birth',
        label: 'date of birth',
    },
    {
        id: '7-home country',
        label: 'home country',
    },
    {
        id: '8-fav date',
        label: 'fav date',
    },
    {
        id: '9-receptionsjahr',
        label: 'receptionsjahr',
    },
    {
        id: '10-secondary emails',
        label: 'secondary emails',
    },
];