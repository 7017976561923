import React from "react";

export const EB_Poll = (props) => {

    let content;

    content = <div>
        Poll
    </div>;

    return content;
};