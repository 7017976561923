import { EditorState } from "draft-js";

const focusCurrentBlock = options => () => {
    const { block, setEditorState } = options;

    setEditorState(editorState => {
        const selection = editorState.getSelection().merge({
            anchorKey: block.getKey(),
            anchorOffset: block.getLength(),
            focusKey: block.getKey(),
            focusOffset: block.getLength()
        });

        return EditorState.forceSelection(editorState, selection);
    });
};

export default focusCurrentBlock;
