import React from "react";

export default function EthIcon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
    <g id="eth">
      <path d="M11.998,0 L11.837,0.547 L11.837,16.416 L11.998,16.577 L19.364,12.223 z" fill="#343434"/>
      <path d="M11.998,-0 L4.631,12.223 L11.998,16.577 L11.998,8.874 z" fill="#8C8C8C"/>
      <path d="M11.998,17.972 L11.907,18.082 L11.907,23.735 L11.998,24 L19.369,13.62 z" fill="#3C3C3B"/>
      <path d="M11.998,24 L11.998,17.972 L4.631,13.619 z" fill="#8C8C8C"/>
      <path d="M11.998,16.577 L19.364,12.223 L11.998,8.874 z" fill="#141414"/>
      <path d="M4.632,12.223 L11.998,16.577 L11.998,8.874 z" fill="#393939"/>
    </g>
  </svg>;
}