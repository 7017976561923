import React, {useState} from 'react';
function setHeight() {
    const el = document.getElementById("pdf-viewer");
    if(el) {
        el.height = `${window.innerHeight - 108}px`;
    }
}

export function PDFPreview({url=''}) {

    return <div className="h-full relative">
        <iframe className="absolute top-0 right-0 bottom-0 left-0" title='PDF Viewer' onLoad={()=>{}} id="pdf-viewer" frameBorder={0} src={url} width='100%' height={'100%'} />
    </div>
}