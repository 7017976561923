import {Modifier,RichUtils,EditorState} from 'draft-js';

const clean_split = ['header-one','header-two','header-three','blockquote'];

export const es_handleReturn = (setEditorState,open) => (event, editorState) => {

    let selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const block_type = currentContentBlock.getType();
    const character_offset = selectionState.getAnchorOffset();
    const block_text = currentContentBlock.getText();

    if(open) {
        return 'not-handled';
    }

    if(block_type==='image-with-caption') {
        const editorStateWithSplitBlock = EditorState.push(
            editorState,
            Modifier.splitBlock(
                currentContent,
                selectionState
            ),
            "split-block"
        );
        setEditorState(
            RichUtils.toggleBlockType(editorStateWithSplitBlock, "unstyled")
        );

        return "handled";
    } else if(clean_split.includes(block_type)) {
        const editorStateWithSplitBlock = EditorState.push(
            editorState,
            Modifier.splitBlock(
                currentContent,
                selectionState
            ),
            "split-block"
        );
        setEditorState(
            RichUtils.toggleBlockType(editorStateWithSplitBlock, "unstyled")
        );

        return "handled";
    }

    return "not-handled";
};