import React from "react";

export const EB_CommunityInvite = (props) => {

    let content;

    content = <div>
        COMMUNITY INVITE
    </div>;

    return content;
};