import React from "react";

export default function InstagramIcon(props) {
  return <svg style={{fill:'#3b5998'}} xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
    <defs>
      <radialGradient id="Gradient_1" gradientUnits="userSpaceOnUse" cx="59.448" cy="-493.505" r="34.706" gradientTransform="matrix(0.999, -0.052, 0.044, 0.849, -36.974, 443.801)">
        <stop offset="0" stopColor="#FED576"/>
        <stop offset="0.263" stopColor="#F47133"/>
        <stop offset="0.609" stopColor="#BC3081"/>
        <stop offset="1" stopColor="#4C63D2"/>
      </radialGradient>
      <radialGradient id="Gradient_2" gradientUnits="userSpaceOnUse" cx="42.75" cy="-275.71" r="17.822" gradientTransform="matrix(0.999, -0.052, 0.044, 0.849, -24.362, 253.295)">
        <stop offset="0" stopColor="#FED576"/>
        <stop offset="0.263" stopColor="#F47133"/>
        <stop offset="0.609" stopColor="#BC3081"/>
        <stop offset="1" stopColor="#4C63D2"/>
      </radialGradient>
    </defs>
    <g id="instagram" transform="translate(0, 0)">
      <g id="logo">
        <path d="M4.142,0.629 C3.352,0.937 2.683,1.347 2.015,2.015 C1.347,2.679 0.937,3.352 0.629,4.137 C0.332,4.899 0.132,5.772 0.073,7.05 C0.015,8.329 0,8.738 0,11.998 C0,15.257 0.015,15.667 0.073,16.945 C0.132,18.223 0.337,19.097 0.629,19.858 C0.937,20.648 1.347,21.317 2.015,21.985 C2.683,22.653 3.352,23.063 4.142,23.371 C4.903,23.668 5.777,23.868 7.055,23.927 C8.333,23.985 8.743,24 12.002,24 C15.262,24 15.671,23.985 16.95,23.927 C18.228,23.868 19.101,23.663 19.863,23.371 C20.653,23.063 21.321,22.653 21.99,21.985 C22.658,21.317 23.068,20.648 23.375,19.858 C23.673,19.097 23.873,18.223 23.932,16.945 C23.99,15.667 24.005,15.257 24.005,11.998 C24.005,8.738 23.99,8.329 23.932,7.05 C23.873,5.772 23.668,4.899 23.375,4.137 C23.068,3.352 22.658,2.679 21.995,2.015 C21.326,1.347 20.658,0.937 19.867,0.629 C19.106,0.332 18.233,0.132 16.955,0.073 C15.676,0.015 15.267,0 12.007,0 C8.748,0 8.338,0.015 7.06,0.073 C5.777,0.127 4.903,0.332 4.142,0.629 z M16.852,2.23 C18.023,2.283 18.657,2.479 19.082,2.644 C19.643,2.864 20.043,3.123 20.463,3.542 C20.882,3.962 21.141,4.362 21.36,4.923 C21.526,5.347 21.721,5.982 21.775,7.153 C21.834,8.416 21.843,8.797 21.843,12.002 C21.843,15.208 21.829,15.589 21.775,16.852 C21.721,18.023 21.526,18.657 21.36,19.082 C21.141,19.643 20.882,20.043 20.463,20.463 C20.043,20.882 19.643,21.141 19.082,21.36 C18.657,21.526 18.023,21.721 16.852,21.775 C15.589,21.834 15.208,21.843 12.002,21.843 C8.797,21.843 8.416,21.829 7.153,21.775 C5.982,21.721 5.347,21.526 4.923,21.36 C4.362,21.141 3.962,20.882 3.542,20.463 C3.123,20.043 2.864,19.643 2.644,19.082 C2.479,18.657 2.283,18.023 2.23,16.852 C2.171,15.589 2.161,15.208 2.161,12.002 C2.161,8.797 2.176,8.416 2.23,7.153 C2.283,5.982 2.479,5.347 2.644,4.923 C2.864,4.362 3.123,3.962 3.542,3.542 C3.962,3.123 4.362,2.864 4.923,2.644 C5.347,2.479 5.982,2.283 7.153,2.23 C8.416,2.171 8.797,2.161 12.002,2.161 C15.208,2.161 15.589,2.171 16.852,2.23 z" fill="url(#Gradient_1)"/>
        <path d="M5.84,12.002 C5.84,15.408 8.602,18.165 12.002,18.165 C15.403,18.165 18.165,15.403 18.165,12.002 C18.165,8.602 15.408,5.84 12.002,5.84 C8.597,5.84 5.84,8.597 5.84,12.002 z M16.003,12.002 C16.003,14.213 14.213,16.003 12.002,16.003 C9.792,16.003 8.002,14.213 8.002,12.002 C8.002,9.792 9.792,8.002 12.002,8.002 C14.213,8.002 16.003,9.792 16.003,12.002 z" fill="url(#Gradient_2)"/>
        <path d="M19.853,5.596 C19.853,6.391 19.208,7.036 18.413,7.036 C17.619,7.036 16.974,6.391 16.974,5.596 C16.974,4.801 17.619,4.157 18.413,4.157 C19.208,4.157 19.853,4.801 19.853,5.596 z" fill="#654C9F"/>
      </g>
    </g>
  </svg>;
}