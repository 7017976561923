import {lie_buildRowData} from "./build-row-data";

/*
How importing works

- you can import members with just a first name -> a new user account with a fake email will be created, a member created linked to that user, and last name will be optionally added
- you can import members by matching account email -> if a member is found by the account email, then the profile is updated, otherwise a new member (and if needed user) is created (updating existing members works)
- you can import members by matching member id -> if a member is found by the id, then the profile is updated, if not we try to match based on account email (if present), if neither works and there is a valid first name, we create a fake account

variations
1 - member id, account email, first name*
2 - member id, account email*
3 - member id, first name*
4 - member id (STOP)
5 - account email, first name*
6 - account email*
7 - first name*

* - last name optional

--> need to think about what is with the user exists globally and has logged in thing..
import flows
a - try to match by member id, if fail try to match by account email, if fail create member by getting user with email (if no user is found, create one)
b - try to match by member id, if fail try to match by account email, if fail create member by getting user with email (if no user is found, create one with email prefix as first name)
c - try to match by member id, if fail create member by creating user with fake email
d - try to match member id, if no first name / account email are present -> stop.
e - try to match by account email, if fail create member, if fail create user by getting user with email (if no user is found, create one with email prefix as first name)
f - try to match by account email, if fail create member, if fail create member by creating user with fake email
g - create new user with fake email and use that to create member
h - import

*/

const import_notes = {
  'a': "If a member is matched by their ID or their account email, their profile will be updated - if not, a new member will be created.",
  'b': "If a member is matched by their ID or their account email, their profile will be updated - if not, a new member will be created with the email handle as the members' first name.",
  'c': "If a member is matched by their ID, their profile will be updated - if not, a new member will be created.",
  'd': "If a member is matched by their ID, their profile will be updated - if not, new members CANNOT be created, either the first name or account email must also be matched.",
  'e': "If a member is matched by their account email, their profile will be updated - if not, a new member will be created.",
  'f': "If a member is matched by their account email, their profile will be updated - if not, a new member will be created with the email handle as the members' first name.",
    'g': "New members will be created for each valid row.",
    'h': "Please match one of the following fields to continue: member ID, account email, or first name."
};

function getImportCase(mid,aem,fn,ln,full_name="") {
    if(mid&&aem&&(fn||full_name)) {
        return 'a';
    } else if(mid&&aem) {
        return 'b'
    } else if(mid&&(fn||full_name)) {
        return 'c'
    } else if(mid) {
        return 'd'
    } else if(aem&&(fn||full_name)) {
        return 'e'
    } else if(aem) {
        return 'f'
    } else if(fn||full_name) {
        return 'g'
    } else {
        return 'h'
    }
}

function intersection() {
    var result = [];
    var lists;

    if(arguments.length === 1) {
        lists = arguments[0];
    } else {
        lists = arguments;
    }

    for(var i = 0; i < lists.length; i++) {
        var currentList = lists[i];
        for(var y = 0; y < currentList.length; y++) {
            var currentValue = currentList[y];
            if(result.indexOf(currentValue) === -1) {
                var existsInAll = true;
                for(var x = 0; x < lists.length; x++) {
                    if(lists[x].indexOf(currentValue) === -1) {
                        existsInAll = false;
                        break;
                    }
                }
                if(existsInAll) {
                    result.push(currentValue);
                }
            }
        }
    }
    return result;
}

export function getImportSummary(data,all_blocks_data,key_block_id,mapping) {
    let valid_records = 0;
    let data_field_key = '';

    let missing_key = 0;

    const mapping_entries = Object.entries(mapping);
    const mapping_items = Object.values(mapping);
    // todo adjust for full name
    const account_email_mapped = mapping_items.filter(a => a.data_field === 'account_email')[0];
    const member_id_mapped = mapping_items.filter(a => a.data_field === 'docid')[0];
    const full_name_mapped = mapping_items.filter(a => a.data_field === 'full_name')[0];
    const first_name_mapped = mapping_items.filter(a => a.data_field === 'about.first_name')[0];
    const last_name_mapped = mapping_items.filter(a => a.data_field === 'about.last_name')[0];

    let import_case = getImportCase(member_id_mapped,account_email_mapped,first_name_mapped,last_name_mapped,full_name_mapped);

    let note = import_notes[import_case];

    const data_entries = Object.entries(data);

    let bl1, bl2, bl3, bl4;
    let b1, b2, b3, b4;
    let t1, t2, t3, t4;
    let v1=[], v2=[], v3=[], v4=[];
    let iv1=[], iv2=[], iv3=[], iv4=[];

    if(member_id_mapped) {
        bl1 = mapping_entries.filter(a=>a[1].data_field==='docid')[0];
        b1 = all_blocks_data.filter(a => a.id === bl1[0])[0];
        t1 = data_entries.map(([row_number, entry]) => lie_buildRowData(row_number, entry, b1, mapping));
        v1 = t1.filter(it => it.value.valid&&it.value.value).map(a=>a.number);
        iv1 = t1.filter(it => !!it.error||!it.value.value).map(a=>a.number);
    }

    if(account_email_mapped) {
        bl2 = mapping_entries.filter(a=>a[1].data_field==='account_email')[0];
        b2 = all_blocks_data.filter(a => a.id === bl2[0])[0];
        t2 = data_entries.map(([row_number, entry]) => lie_buildRowData(row_number, entry, b2, mapping));
        v2 = t2.filter(it => it.value.valid&&it.value.value).map(a=>a.number);
        iv2 = t2.filter(it => !!it.error||!it.value.value).map(a=>a.number);
    }

    if(first_name_mapped) {
        bl3 = mapping_entries.filter(a=>a[1].data_field==='about.first_name')[0];
        b3 = all_blocks_data.filter(a => a.id === bl3[0])[0];
        t3 = data_entries.map(([row_number, entry]) => lie_buildRowData(row_number, entry, b3, mapping));
        v3 = t3.filter(it => it.value.valid&&it.value.value).map(a=>a.number);
        iv3 = t3.filter(it => !!it.error||!it.value.value).map(a=>a.number);
    }

    if(full_name_mapped) {
        bl4 = mapping_entries.filter(a=>a[1].data_field==='full_name')[0];
        b4 = all_blocks_data.filter(a => a.id === bl4[0])[0];
        t4 = data_entries.map(([row_number, entry]) => lie_buildRowData(row_number, entry, b4, mapping));
        v4 = t4.filter(it => it.value.valid&&it.value.value).map(a=>a.number);
        iv4 = t4.filter(it => !!it.error||!it.value.value).map(a=>a.number);
    }
    let all_invalid = [], all_valid = [];

    if(member_id_mapped&&account_email_mapped&&first_name_mapped) {
        all_invalid = intersection(iv1,iv2,iv3);
        all_valid = [].concat(v1,v2,v3);
    } else if(member_id_mapped&&account_email_mapped&&full_name_mapped) {
        all_invalid = intersection(iv1,iv2,iv4);
        all_valid = [].concat(v1,v2,v4);
    } else if(member_id_mapped&&first_name_mapped) {
        all_invalid = intersection(iv1,iv3);
        all_valid = [].concat(v1,v3);
    } else if(account_email_mapped&&first_name_mapped) {
        all_invalid = intersection(iv2,iv3);
        all_valid = [].concat(v2,v3);
    }  else if(member_id_mapped&&full_name_mapped) {
        all_invalid = intersection(iv1,iv4);
        all_valid = [].concat(v1,v4);
    } else if(account_email_mapped&&full_name_mapped) {
        all_invalid = intersection(iv2,iv4);
        all_valid = [].concat(v2,v4);
    } else if(member_id_mapped) {
        all_invalid = [...iv1];
        all_valid = [...v1];
    } else if(account_email_mapped) {
        all_invalid = [...iv2];
        all_valid = [...v2];
    } else if(first_name_mapped) {
all_invalid = [...iv3];
        all_valid = [...v3];
    } else if(full_name_mapped) {
        all_invalid = [...iv4];
        all_valid = [...v4];
    }

    valid_records = all_valid.length;
    missing_key = all_invalid.length;

    return {
        valid_records,
        data_field_key,
        all_invalid,
        all_valid,
        note,
        missing_key
    }
}