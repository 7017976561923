import {EditorState, Modifier, RichUtils, SelectionState} from "draft-js";

export function sc_handleConvertToHeader(editorState,type) {
    const nes = RichUtils.toggleBlockType(editorState, type);
    const selection = nes.getSelection();
    const cs = nes.getCurrentContent();
    const focus_block_key = selection.getAnchorKey();
    const content_block = cs.getBlockForKey(focus_block_key);

    const temp_selection = SelectionState.createEmpty(focus_block_key);
    const updatedSelection = temp_selection.merge({
        focusKey: focus_block_key,
        focusOffset: content_block.getLength(),
    });

    const ncs = Modifier.replaceText(cs,updatedSelection,"");

    const newContent = ncs.merge({
        selectionBefore: selection,
        selectionAfter: ncs.getSelectionAfter().set('hasFocus', true)
    });

    return EditorState.push(nes, newContent, 'remove-range');
}